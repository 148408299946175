import { createEventListener, trackComponent } from "../../utils/events/trackComponent";
import { trackAmplitudeEvents } from "../../utils/events/amplitude";

// app/javascript/js/organism/topalert/alert.js
const bbLink = document.querySelector(".topalert__inner .container p a");

if (bbLink) {
  createEventListener(
    bbLink,
    () => {
      const amplitudePropsDataset = document.querySelector(".amplitude-link-props").dataset;
      const ampProps = JSON.parse(amplitudePropsDataset.amplitudeProps);
      trackAmplitudeEvents("click-user-alert", ampProps.eventProperties);
    },
    "click"
  );
}

trackComponent({
  selector: ".topalert--basic",
  trackIfShown: true,
  ifShownAction: "show-alert",
  childrenDefaultAction: "alert",
  category: "promo-alert-basic",
  nonInteraction: true,
  gaOnly: true,
});
