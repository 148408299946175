import { Experiment } from "@amplitude/experiment-js-client";
import { setCookie, getCookie } from "./cookies";
import { UUIDv4 } from "./uuid";

export const cookieSessionName = window.ENV.AMPLITUDE_COOKIE_SESSION_NAME;
export const cookieDeviceName = window.ENV.AMPLITUDE_COOKIE_DEVICE_NAME;
export const amplitudeKey = window.ENV.AMPLITUDE_KEY;
export const amplitudeExperimentDeploymentKey = window.ENV.AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY;
export const amplitudeTrackingEnabled = window.ENV.AMPLITUDE_TRACKING_ENABLED === "true";

export const experiment = Experiment.initializeWithAmplitudeAnalytics(
  amplitudeExperimentDeploymentKey
);

export const setCookieSessionId = (sessionId) => {
  if (!amplitudeTrackingEnabled) {
    return;
  }

  const sessionCookie = getCookie(cookieSessionName);

  if (!sessionCookie || sessionCookie !== sessionId) {
    setCookie(cookieSessionName, sessionId);
  }
};

export const getCookieDeviceId = () => {
  const deviceId = getCookie(cookieDeviceName);

  if (deviceId) {
    return deviceId;
  }

  const newDeviceId = UUIDv4();

  if (!amplitudeTrackingEnabled) {
    return null;
  }

  setCookie(cookieDeviceName, newDeviceId);

  return newDeviceId;
};
