/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_bundle_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "./utils/events/globalEventsTracker";
import "./molecules/navigation/mainDropdown";
import "./molecules/navigation/events";
import "./molecules/fields/showPasswordField"; // MODAL
import "./organism/modalLogin/index"; // MODAL
import "./organism/modalCourses/index"; // MODAL
import "../packs/amplitude/amplitude";
import "../packs/alerts/fixedAlert";
import "./utils/widgets/apple"; // MODAL
import "./molecules/stickyBanner";
import "./organism/courseCard/events";
import "./organism/tooltip";
import "./organism/followButton";
import "./molecules/fields/emailCorrectorField"; // MODAL
import "./molecules/fields/signUpForm"; // MODAL
import "./atoms/modals/modals";
import "./organism/topalert/events";
import "./atoms/charsCounter"; // Not modal but weird -
import "./molecules/fields/enterFocusEmail"; // MODAL
import "../packs/utils/userConsent";
import "./utils/pushNotification";
import "./molecules/search/expandSearch";
import "./molecules/countdown/fake";
import "./molecules/link/link";

import { Application } from "@hotwired/stimulus";
import Countdown from "./controllers/countdown_controller";
import Header from "./controllers/header_controller";

window.Stimulus = Application.start();
window.Stimulus.register("countdown", Countdown);
window.Stimulus.register("header", Header);
